import React, { useState } from 'react';
import { auth } from '../firebase'; // Asegúrate de que la ruta sea correcta
import { signOut } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import '../styles/Account.css'; // Importamos los estilos

const Account = () => {
  const [profilePic, setProfilePic] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSignOut = async () => {
    try {
      await signOut(auth);
      navigate('/'); // Redirige al usuario a la página de inicio de sesión o a la página principal
    } catch (error) {
      setError(error.message);
    }
  };

  const handleProfilePicChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      // Aquí podrías subir la imagen a Firebase Storage y luego actualizar el perfil del usuario
      const reader = new FileReader();
      reader.onloadend = () => {
        setProfilePic(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <div className="account-page">
      <h1>Mi Cuenta</h1>
      
      <div className="profile-section">
        <h2>Foto de Perfil</h2>
        <img src={profilePic || 'default-profile-pic.jpg'} alt="Profile" className="profile-pic" />
        <input type="file" accept="image/*" onChange={handleProfilePicChange} />
      </div>

      <div className="actions-section">
        <button onClick={handleSignOut} className="button">Cerrar Sesión</button>
      </div>

      {error && <p className="error-message">{error}</p>}
    </div>
  );
};

export default Account;

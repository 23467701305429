import React, { useState } from "react";
import { collection, addDoc } from "firebase/firestore";
import { firestore } from "../firebase";
import KeyForm from "./KeyForm"; // Importar componente reutilizable
import Popup from './Popup'; // Importamos el Popup reutilizable
import { PlusCircle } from 'lucide-react'; // Ícono de Lucide

const generateKey = () => {
  // Generar una clave al azar en formato XXXX-XXXX-XXXX-XXXX
  return `${Math.random().toString(36).substr(2, 4).toUpperCase()}-${Math.random().toString(36).substr(2, 4).toUpperCase()}-${Math.random().toString(36).substr(2, 4).toUpperCase()}-${Math.random().toString(36).substr(2, 4).toUpperCase()}`;
};

const KeyRegistration = ({ fetchKeys }) => {
  const [formData, setFormData] = useState({
    name: "",
    date: "",
    service: "",
    state: "",
    info: "",
    attendance: false,
  });

  const [error, setError] = useState("");
  const [isPopupOpen, setIsPopupOpen] = useState(false); // Estado para controlar el popup

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleAttendanceChange = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      attendance: e.target.checked,
    }));
  };

  const handleAddKey = async (e) => {
    e.preventDefault();
    try {
      const key = generateKey();
      await addDoc(collection(firestore, "keys"), {
        ...formData,
        key,
      });
      setFormData({
        name: "",
        date: "",
        service: "",
        state: "",
        info: "",
        attendance: false,
      });
      setError("");
      fetchKeys(); // Refrescar la lista de keys
      togglePopup(); // Cerrar popup después de agregar la clave
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <>
      <button className="button" onClick={togglePopup}>
        <PlusCircle size={20} className="button-icon" /> {/* Ícono de agregar */}
        <span>Generar Clave</span>
      </button>

      {isPopupOpen && (
        <Popup isOpen={isPopupOpen} togglePopup={togglePopup}>
          <h2>Registro de Claves</h2>
          <KeyForm
            formData={formData}
            onInputChange={handleInputChange}
            onAttendanceChange={handleAttendanceChange}
            onSubmit={handleAddKey}
            error={error}
          />
        </Popup>
      )}
    </>
  );
};

export default KeyRegistration;

// src/pages/Admin.js
import React from 'react';
import Auth from '../components/Auth';
import KeyManager from '../components/KeyManager';

const Admin = () => {
  return (
    <div className="admin-page">
      <KeyManager />
    </div>
  );
};

export default Admin;

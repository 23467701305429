import React, { useState } from "react";
import { firestore } from "../firebase";
import { collection, getDocs } from "firebase/firestore";
import Ticket from "./Ticket";
import '../styles/KeyChecker.css';


const KeyChecker = () => {
  const [key, setKey] = useState("");
  const [ticket, setTicket] = useState(null);
  const [error, setError] = useState("");

  const handleKeyCheck = async () => {
    try {
      const querySnapshot = await getDocs(collection(firestore, "keys"));
      const keyDoc = querySnapshot.docs.find((doc) => doc.data().key === key);
      if (keyDoc) {
        const keyData = keyDoc.data();
        setTicket({
          key: keyData.key,
          name: keyData.name,
          date: keyData.date,
          service: keyData.service,
        });
        setError("");
      } else {
        setTicket(null);
        setError("Entrada no encontrada.");
      }
    } catch (error) {
      setError(error.message);
    }
  };
  return (
    <div className="key-checker">
      <label for="key"><h1>Ingresa aquí la clave de tu <span className="golden">Experience Ticket</span> y disfruta una nueva forma de vivir.</h1></label>
      <input
        name="key"
        id="key"
        type="text"
        value={key}
        onChange={(e) => setKey(e.target.value)}
        placeholder="XXXX-XXXX-XXXX-XXXX"
        className="input"
      />
      <button onClick={handleKeyCheck} className="button">Descubrir experiencia</button>
      {error && <p className="error-message">{error}</p>}
      {ticket && <Ticket ticket={ticket} />} {/* Usar el componente Ticket con efectos */}
    </div>
  );
};

export default KeyChecker;

// ProtectedRoute.js
import React, { useState, useEffect } from 'react';
import { Route, Navigate } from 'react-router-dom';
import { auth } from '../firebase'; // Asegúrate de importar la configuración de Firebase

const ProtectedRoute = ({ element: Component, ...rest }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      setIsAuthenticated(!!user);
    });

    return () => unsubscribe(); // Limpia la suscripción al desmontar el componente
  }, []);

  if (isAuthenticated === null) {
    // Puedes agregar un spinner de carga o algo similar aquí
    return <div>Loading...</div>;
  }

  return isAuthenticated ? Component : <Navigate to="/" />;
};

export default ProtectedRoute;

// src/components/Header.js

import React from 'react';
import { useNavigate } from 'react-router-dom';
import ThemeToggleButton from './ThemeToggleButton';
import LoginButton from './LoginButton';  // Componente de login
import AdminKeyButton from './AdminKeyButton';  // El nuevo botón de registro de claves
import { ReactComponent as Logo } from '../logo.svg';
import '../styles/Header.css'; // Importamos los estilos

const Header = () => {
  const navigate = useNavigate();

  const handleLogoClick = () => {
    navigate('/');
  };

  return (
    <header className="header">
      <div className="header-content">
        <Logo
          alt="Logo"
          className="logo"
          onClick={handleLogoClick}
        />
        <div className="header-buttons">
          <AdminKeyButton />
          <ThemeToggleButton />
          <LoginButton />
        </div>
      </div>
    </header>
  );
};

export default Header;

import { useState } from 'react';
import '../styles/Footer.css'; // Importamos los estilos

function Footer() {
  return (
    <footer className="footer">
      <div className="footer-logo">
        <img 
          src="/iso.svg" 
          alt="Isotipo"
          className="iso"
        />
      </div>
    </footer>
  );
}

export default Footer;

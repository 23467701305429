// Popup.js
import React from 'react';
import { X } from 'lucide-react'; // Importa el ícono de cierre
import '../styles/Popup.css'; // Importamos los estilos del popup

const Popup = ({ isOpen, togglePopup, children }) => {
  if (!isOpen) return null; // Si no está abierto, no renderizamos el componente

  return (
    <>
      <div className="popup-overlay" onClick={togglePopup}></div>
      <div className="popup">
        <div className="popup-content">
          <button className="popup-close" onClick={togglePopup}>
            <X className="w-6 h-6" />
          </button>
          {children} {/* Aquí va el contenido dinámico del popup */}
        </div>
      </div>
    </>
  );
};

export default Popup;

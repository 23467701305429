import React, { useState, useEffect, useRef } from "react";
import { MoreHorizontal } from "lucide-react";
import ClipboardButton from "./Clipboard"; // Importar el componente ClipboardButton

const formatDate = (dateString) => {
  const options = { weekday: 'long', day: 'numeric', month: 'long', year: 'numeric' };
  return new Date(dateString).toLocaleDateString('es-ES', options);
};

const KeyListGroupedByDate = ({ keys, toggleAttendance, handleEditClick, getServiceClass }) => {
  const [currentMonth, setCurrentMonth] = useState(new Date()); // Estado para el mes actual
  const monthNavigatorRef = useRef(null); // Referencia para la barra de navegación

  // Agrupar las keys por fecha
  const groupedKeys = keys.reduce((acc, key) => {
    const keyDate = new Date(key.date);
    const monthYear = `${keyDate.getMonth() + 1}-${keyDate.getFullYear()}`; // Formato MM-YYYY
    const day = keyDate.toLocaleDateString('es-ES', { day: 'numeric' });
    const dayOfWeek = keyDate.toLocaleDateString('es-ES', { weekday: 'long' });
    const formattedDate = `${dayOfWeek} ${day}, ${keyDate.toLocaleDateString('es-ES', { month: 'long', year: 'numeric' })}`;
    
    if (!acc[monthYear]) {
      acc[monthYear] = {};
    }
    if (!acc[monthYear][formattedDate]) {
      acc[monthYear][formattedDate] = [];
    }
    acc[monthYear][formattedDate].push(key);
    return acc;
  }, {});

  // Obtener los meses a partir de las keys
  const months = Object.keys(groupedKeys).sort((a, b) => {
    const [monthA, yearA] = a.split("-").map(Number);
    const [monthB, yearB] = b.split("-").map(Number);
    return new Date(yearB, monthB - 1) - new Date(yearA, monthA - 1);
  });

  // Filtrar las keys por el mes actual
  const filteredGroupedKeys = groupedKeys[currentMonth.getMonth() + 1 + '-' + currentMonth.getFullYear()] || {};

  // Ordenar las fechas y las keys dentro de cada fecha
  const sortedDates = Object.keys(filteredGroupedKeys).sort();
  const sortedKeys = sortedDates.map((date) => ({
    date,
    items: filteredGroupedKeys[date].sort((a, b) => a.service.localeCompare(b.service)),
  }));

  // Cambiar mes al hacer click en la barra
  const handleMonthChange = (monthIndex) => {
    const [month, year] = months[monthIndex].split('-').map(Number);
    setCurrentMonth(new Date(year, month - 1));
  };

  // Desplazar automáticamente al mes actual cuando el componente se monta
  useEffect(() => {
    const monthIndex = months.findIndex(
      (monthYear) => {
        const [month, year] = monthYear.split('-').map(Number);
        return (
          month === currentMonth.getMonth() + 1 && year === currentMonth.getFullYear()
        );
      }
    );
    if (monthNavigatorRef.current && monthIndex !== -1) {
      const scrollAmount = monthNavigatorRef.current.scrollWidth / months.length * monthIndex;
      monthNavigatorRef.current.scrollTo({ left: scrollAmount, behavior: "smooth" });
    }
  }, [currentMonth, months]);

  return (
    <div>
      {/* Barra de navegación de meses */}

      <ul className="key-list">
        <h3>Claves existentes</h3>
        <div
        ref={monthNavigatorRef}
        className="month-navigator"
        style={{ display: 'flex', overflowX: 'auto', whiteSpace: 'nowrap' }}
        onWheel={(e) => { // Capturar scroll del mouse
          e.preventDefault();
          monthNavigatorRef.current.scrollLeft += e.deltaY;
        }}
      >
        {months.map((monthYear, index) => {
          const [month, year] = monthYear.split("-");
          const monthName = new Date(year, month - 1).toLocaleString("es-ES", { month: "long" }).toUpperCase();
          return (
            <button
              key={monthYear}
              className={`button ${index === months.findIndex(m => m === `${currentMonth.getMonth() + 1}-${currentMonth.getFullYear()}`) ? 'selected' : ''}`}
              onClick={() => handleMonthChange(index)}
              style={{ padding: '10px', cursor: 'pointer' }}
            >
              {`${monthName} ${year}`}
            </button>
          );
        })}
      </div>
        {sortedKeys.map(({ date, items }) => (
          <div key={date} className="date-group">
            <h4>{date}</h4>
            {items.map((key) => (
              <li
                key={key.id}
                className={`key-info ${key.attendance ? "attending" : "not-attending"} ${getServiceClass(key.service)}`}
                onClick={() => toggleAttendance(key.id, key.attendance)}
              >
                <div className="key-info-content" onClick={() => toggleAttendance(key.id, key.attendance)}>
                  <p className="name">{key.name}</p>
                  <p className="data">
                    {key.state} - {key.info}
                  </p>
                  <p className="key">{key.key}</p>
                </div>
                <button className="more-button" onClick={() => handleEditClick(key)}>
                  <MoreHorizontal />
                </button>
                <ClipboardButton textToCopy={`${key.key} • ${key.name}`} />
              </li>
            ))}
          </div>
        ))}
      </ul>
    </div>
  );
};

export default KeyListGroupedByDate;

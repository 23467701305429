import React from "react";
import { ChevronDown } from "lucide-react"; // Importar ícono

const KeyForm = ({ formData, onInputChange, onAttendanceChange, onSubmit, error }) => (
  <form onSubmit={onSubmit} className="key-form">
    <div className="key-field">
      <label htmlFor="name">Nombre Completo</label>
      <input
        type="text"
        id="name"
        name="name"
        className="input"
        value={formData.name}
        onChange={onInputChange}
        placeholder="Nombre y apellido"
        required
      />
    </div>

    <div className="key-field">
      <label htmlFor="date">Fecha</label>
      <input
        type="date"
        id="date"
        name="date"
        className="date"
        value={formData.date}
        onChange={onInputChange}
        required
      />
    </div>

    <div className="key-field">
      <label htmlFor="service">Servicio</label>
      <span className="select-container">
        <select
          id="service"
          name="service"
          className="select"
          value={formData.service}
          onChange={onInputChange}
          required
        >
          <option value="Paddleboard: Sunrise Experience">Paddleboard: Sunrise Experience</option>
          <option value="Paddleboard: Sunshine Experience">Paddleboard: Sunshine Experience</option>
          <option value="Paddleboard: Sunset Experience">Paddleboard: Sunset Experience</option>
        </select>
        <ChevronDown className="select-icon" />
      </span>
    </div>

    <div className="key-field">
      <label htmlFor="state">Estado</label>
      <span className="select-container">
        <select
          id="state"
          name="state"
          className="select"
          value={formData.state}
          onChange={onInputChange}
          required
        >
          <option value="Cliente">Cliente</option>
          <option value="Influencer">Influencer</option>
          <option value="Invitado">Invitado</option>
        </select>
        <ChevronDown className="select-icon" />
      </span>
    </div>

    <div className="key-field">
      <label htmlFor="info">Indicación Especial</label>
      <span className="select-container">
        <select
          id="info"
          name="info"
          className="select"
          value={formData.info}
          onChange={onInputChange}
          required
        >
          <option value="Sin indicación especial">Sin indicación especial</option>
          <option value="Cumpleaños">Cumpleaños</option>
          <option value="Aniversario">Aniversario</option>
        </select>
        <ChevronDown className="select-icon" />
      </span>
    </div>

    <div className="key-field">
      <label htmlFor="attendance">Asistencia</label>
      <input
        type="checkbox"
        id="attendance"
        className="checkbox"
        checked={formData.attendance}
        onChange={onAttendanceChange}
      />
    </div>

    <button type="submit" className="button">Guardar</button>
    {error && <p className="error-message">{error}</p>}
  </form>
);

export default KeyForm;

// LoginButton.js
import React, { useState } from 'react';
import { User } from 'lucide-react';  // Importa el ícono de usuario
import Auth from './Auth';  // Asegúrate de que la ruta sea correcta
import Popup from './Popup';  // Importa el nuevo componente Popup

const LoginButton = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  return (
    <>
      <button onClick={togglePopup} className="small-button">
        <User className="w-6 h-6" />
      </button>

      {/* Aquí usamos el componente Popup */}
      <Popup isOpen={isPopupOpen} togglePopup={togglePopup}>
        <Auth /> {/* El contenido del popup será el componente de autenticación */}
      </Popup>
    </>
  );
};

export default LoginButton;

// src/firebase.js
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore, doc, getDoc, setDoc } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";

// Tu configuración de Firebase
const firebaseConfig = {
  apiKey: "AIzaSyA8i9hYkBhpgFb-ht1kXIEQS044DauDH6w",
  authDomain: "ooooo-69d0d.firebaseapp.com",
  projectId: "ooooo-69d0d",
  storageBucket: "ooooo-69d0d.appspot.com",
  messagingSenderId: "308104519212",
  appId: "1:308104519212:web:7bd383ea96914b70d07b89",
  measurementId: "G-E82G6J1EY7"
};

// Inicializa Firebase
const app = initializeApp(firebaseConfig);

// Inicializa Firestore
const firestore = getFirestore(app);

// Inicializa Auth
const auth = getAuth(app);

// Inicializa Analytics
const analytics = getAnalytics(app);

// Exporta los módulos necesarios
export { auth, firestore, analytics, doc, getDoc, setDoc };

// Clipboard.js
import React from "react";
import { Clipboard } from "lucide-react"; // Importar el ícono

const ClipboardButton = ({ textToCopy }) => {
  const handleCopy = () => {
    navigator.clipboard.writeText(textToCopy)
      .then(() => {
        alert("Texto copiado al portapapeles!");
      })
      .catch((error) => {
        console.error("Error al copiar al portapapeles:", error);
      });
  };

  return (
    <button className="clipboard-button" onClick={handleCopy}>
      <Clipboard /> {/* Ícono de copiar */}
    </button>
  );
};

export default ClipboardButton;
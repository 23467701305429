// src/components/AdminKeyButton.js

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../firebase'; // Importa tu configuración de Firebase
import { KeyRound } from 'lucide-react';

const AdminKeyButton = () => {
  const [isAdmin, setIsAdmin] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        const email = user.email || '';
        setIsAuthenticated(true);
        setIsAdmin(email.endsWith('@ooooo.pe'));  // Verifica si el usuario es admin
      } else {
        setIsAuthenticated(false);
        setIsAdmin(false);
      }
    });

    return () => unsubscribe();  // Limpia la suscripción cuando el componente se desmonta
  }, []);

  const handleAdminClick = () => {
    navigate('/admin');  // Redirige a la página de administración
  };

  // Si el usuario no es admin o no está autenticado, no mostramos el botón
  if (!isAuthenticated || !isAdmin) {
    return null;
  }

  return (
    <button onClick={handleAdminClick} className="small-button">
      <KeyRound className="w-6 h-6" />
    </button>
  );
};

export default AdminKeyButton;

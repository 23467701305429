import React, { useEffect, useRef } from 'react';
import VanillaTilt from 'vanilla-tilt';
import '../styles/Ticket.css'; // Asegúrate de tener el archivo CSS adecuado

const Ticket = ({ ticket }) => {
  const ticketRef = useRef(null);

  useEffect(() => {
    const tiltOptions = {
      reverse: true,
      max: 15,
      speed: 3000,
      glare: true, // Activar el efecto de glare
      "max-glare": 0.5, // Ajusta el valor del glare aquí
    };

    if (ticketRef.current) {
      VanillaTilt.init(ticketRef.current, tiltOptions);
    }

    return () => {
      if (ticketRef.current) {
        VanillaTilt.init(ticketRef.current, null); // Limpia la instancia
      }
    };
  }, []);

  return (
    <div className='ticket-wrapper'>
      <div className="ticket" ref={ticketRef}>
        <div className="ticket-content">
          {/* <p><strong>Key:</strong> {ticket.key}</p> */}
          <h1>{ticket.name}</h1>
          <h2>{ticket.service}</h2>
          <h3>{ticket.date}</h3>
        </div>
      </div>
    </div>
  );
};

export default Ticket;
